<template>
  <div class="proposal__wrap">
    <div class="section shadow filter__wrap">
      <div>
        <el-button type="primary">banner管理</el-button>
        <el-button type="text" @click="$router.push('/notify')">系统通知</el-button>
      </div>
      <el-button type="primary" icon="el-icon-plus" size="mini" @click="showEdit">添加banner</el-button>
    </div>
    <!-- 表格 -->
    <div class="table__wrap">
      <VTable
        has-pagionation
        title=''
        addText='添加'
        :field="field"
        :loading="table.loading"
        :data="table.data"
        :page='table.params.page'
        :pageSize='table.params.count'
        :total='table.total'
        :tree-props="{children: 'children', hasChildren: 'has_child'}"
        :hasPagionation="true"
        :default-expand-all="true"
        :showOverflowTooltip="true"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      >
        <template v-slot:image="{row}">
          <el-image 
          style="width: 100px; height: 50px"
          :src="row.image" 
          :preview-src-list="[row.image]"
          fit="cover">
        </el-image>
        </template>
        <template v-slot:type="{row}">
          <span>{{typeOpt[row.type]}}</span>
        </template>
        <template v-slot:action="{row}">
          <el-button type="text" icon="el-icon-edit" @click="showEdit(row)">编辑</el-button>
          <el-button type="text" icon="el-icon-delete" class="delBtn" @click="handleDetail(row)">删除</el-button>
        </template>
      </VTable>
    </div>

    <edit ref="edit" @refresh="getTable"></edit>
    
  </div>
</template>

<script>
import VTable from '@/components/VTable';
import Edit from './components/Edit.vue'
import { mixinTable } from '@/mixins/table.js'
export default {
  name: 'HomeBanner',
  mixins:[mixinTable],
  components: {
    VTable,
    Edit,
  },
  data() {
    return {
      field: [
        { name: "image", label: "缩略图", hidden: false },
        { name: "title", label: "标题", hidden: false },
        { name: "type", label: "属性", hidden: false },
        { name: "create_time", label: "发布时间", hidden: false },
        { name: "action", label: "操作", fixed:"right", width: "160", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          title: "",
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      typeOpt: {
        1:'链接',
        2:'文本',
        3:'企业',
        4:'岗位'
      }
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      this.$http.post('/admin/banner/list', this.table.params).then(res => {
        this.table.data = res.data.list;
        this.table.total = res.data.total;
      })
    },
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      if (!!row.id) {
        dom.getDetail(row)
      }
      dom = null
    },
    // 删除
    handleDetail(row) {
      this.$confirm('请确认删除操作?','提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$http.post('/admin/banner/delete', {id:row.id}).then(res => {
          if(res.code === 1) {
            this.$message.success('操作成功！')
            this.getTable()
          } else {
            this.$message.error(res.msg)
          }
        })
      }).catch(() =>{})
    },
  }
}
</script>

<style scoped lang="scss">
  .proposal__wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px;

    .filter__wrap {
      padding: 10px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
    }

    .table__wrap {
      flex: 1;
      padding: 0;
      box-sizing: border-box;
    }
  }
</style>