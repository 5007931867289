<template>
  <el-dialog
    append-to-body
    width="600px"
    :title="`${isChange ? '修改' : '新增'}`"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      v-loading="loading"
      element-loading-text="加载中…"
      ref='elFormDom'
      label-width='110px'
      label-position='right'
      :model='form.data'
      :rules='form.rules'
    >
      <el-form-item prop='type' label='选择类型'>
        <el-select v-model="form.data.type" clearable filterable placeholder="请选择">
          <el-option
            v-for="item in typeOpt"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop='image' label='banner图'>
        <el-upload
          class="avatar-uploader"
          action=""
          :headers="{ token: token }"
          :show-file-list="false"
          :before-upload="beforeAvatarUpload"
          :http-request="httpRequest"
          >
          <img v-if="this.form.data.image" :src="this.form.data.image" class="avatar" style="object-fit: contain;">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <template>
        <el-form-item prop="title" label="标题">
          <el-input placeholder="请输入标题" v-model="form.data.title" clearable></el-input>
        </el-form-item>
      </template>
      <el-form-item prop="sort" label="展示顺序">
        <el-input placeholder="请输入展示顺序" v-model.number="form.data.sort" clearable></el-input>
      </el-form-item>
      <!-- type：1 链接 -->
      <template v-if="this.form.data.type === 1">
        <el-form-item prop="jump_url" label="添加链接">
          <el-input placeholder="请输入链接" v-model="form.data.jump_url" clearable></el-input>
        </el-form-item>
      </template>
      <!-- type:3 企业 -->
      <template v-if="this.form.data.type === 3">
        <el-form-item prop="company_id" label="企业ID">
          <el-select v-model="form.data.company_id" clearable placeholder="请选择">
            <el-option
              v-for="item in companyOpt"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </template>
      <!-- type:4 岗位 -->
      <template v-if="this.form.data.type === 4">
        <el-form-item prop="job_id" label="岗位ID">
          <el-select v-model="form.data.job_id" clearable placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.title"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </template>
    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>取消</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import common from '@/util'
  import {getStorage} from '@/storage'
  export default {
    name: 'SystemUnitEdit',
    data() {
      return {
        token: getStorage('token'),
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            type: 1,
            image: '',
            jump_url: '',
            company_id: '',
            job_id: '',
            title: '',
            sort: '',
          },
          rules: {
            type: [{ required: true, message:'请输入', trigger: 'change' }],
            image: [{ required: true, message: '请输入', trigger: 'change' }],
            jump_url: [{ required: true, message: '请输入', trigger: 'change' }],
            company_id: [{ required: true, message: '请输入', trigger: 'change' }],
            job_id: [{ required: true, message: '请输入', trigger: 'change' }],
            title: [{ required: true, message: '请输入', trigger: 'change' }],
            sort: [
              { required: true, message: '请输入展示顺序', trigger: 'blur' },
              { type: 'number', message: '展示顺序必须为数字'}
            ],
          }
        },
        typeOpt: [
          {
            value: 1,
            label: '链接'
          },
          {
            value: 2,
            label: '文本'
          },
          {
            value: 3,
            label: '企业'
          },
          {
            value: 4,
            label: '岗位'
          }
        ],
        companyOpt: [],
        quartersOpt: [],
      }
    },
    created() {
      this.getBase();
    },
    methods: {
      getBase() {
        // 获取企业
        this.$http.get('/admin/company/list',{params:{count:1000,type:2}}).then(res => {
          if(res.code === 1) {
            this.companyOpt = res.data.list;
          } else {
            this.$message.error(res.msg)
          }
        })
        // 获取岗位
        this.$http.get('/admin/company/jobList',{params:{count:1000}}).then(res => {
          if(res.code === 1) {
            this.quartersOpt = res.data.list;
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      // 获取详情
      getDetail(row) {
        console.log(row)
        this.isChange = true
        this.$http.get('admin/banner/info', {params:{id: row.id} }).then(res => {
          if(res.code === 1) {
            common.deepClone(this.form.data, res.data)
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
           this.form.loading = true;
           let apiUrl = !this.isChange ? '/admin/banner/add' : '/admin/banner/edit'
            this.$http.post(apiUrl, this.form.data).then(res => {
              if(res.code === 1) {
                this.toggle(false);
                this.$emit('refresh')
              } else {
                this.$message.error(res.msg)
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
      beforeAvatarUpload(file) {
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
          this.$message.error('上传文件大小不能超过 5MB!');
        }
        return isLt5M;
      },
      httpRequest(file) {
        let formData = new FormData();
        formData.append('file', file.file);
        this.$http.post('common/qiniu/uploadFile', {formData, type:'upload'}).then(res => {
          this.form.data.image = res.data.all_path_url;
        })
      },

    }
  }
</script>
